import React from "react";

// Images
import NavIconLight from "./media/nav-icon-light.png";
import NavIconDark from "./media/nav-icon-dark.png";
import AppStore from "./media/app-store.png";
import GooglePlay from "./media/google-play.png";

// Material
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Fab from "@material-ui/core/Fab";

import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import BarChartIcon from "@material-ui/icons/BarChart";
import SwapHorizOutlinedIcon from "@material-ui/icons/SwapHorizOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SaveIcon from "@material-ui/icons/Save";
import ReplyIcon from "@material-ui/icons/Reply";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import Brightness2OutlinedIcon from "@material-ui/icons/Brightness2Outlined";
import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";

import ColorBar from "react-color-bar";
import { useMediaQuery } from "react-responsive";

import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
} from "recharts";

import { MobileView, isIOS, isAndroid } from "react-device-detect";

import cookie from "react-cookies";

// Others
import { Line } from "rc-progress";
import axios from "axios";
import MetaTags from "react-meta-tags";
import Modal from "react-modal";
import { Divider } from "@material-ui/core";

// Tooltip
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: 18,
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
  const [darkState, setDarkState] = React.useState(true);

  const mainPrimaryColor = darkState ? "#7dd957" : "#000";
  const mainSecondaryColor = darkState ? "#7dd957" : "#7dd957";
  const backgroundColor = darkState ? "#383838" : "#F5F5F5";
  const backgroundColorDark = darkState ? "#16192E" : "#EDF0F6";
  const lightColor = darkState ? "#BBBBBB" : "#777777";

  const palletType = darkState ? "dark" : "light";
  const darkTheme = createMuiTheme({
    typography: {},
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
        light: lightColor,
      },
      background: {
        default: backgroundColor,
        dark: backgroundColorDark,
      },
    },
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 959px)" });

  // Styling
  const useStyles = makeStyles(() => ({
    legendLabel: {
      fontWeight: 400,
      fontSize: 16,
      color: darkState ? "white" : "black",
      display: "inline-block",
    },
    legendOdd: {
      paddingRight: isTabletOrMobile ? 0 : 10,
    },
    inputTitle: {
      fontWeight: 400,
      fontSize: 14,
      color: darkTheme.palette.secondary.light,
    },
    inputValue: {
      fontWeight: 600,
      fontSize: 18,
    },
    menuButton: {
      marginRight: darkTheme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    modalBackground: {
      opacity: 0.2,
      backgroundColor: darkTheme.palette.background.default,
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    getData();
    if (typeof cookie.load("lightMode") !== "undefined") {
      setDarkState(false);
    }
  }, []);

  const [data, setData] = React.useState({
    title: "Mortgage Calculator",
    home_price: "300,000",
    down_payment_amt: "60,000",
    down_payment_per: "20",
    interest_rate: "3.25",
    loan_term: "30",
    property_tax_amt: "3,600",
    property_tax_per: "1.2",
    hoa: "",
    home_owner_insurance_amt: "50",
    home_owner_insurance_per: "0.2",
    mortgage_insurance_amt: "0",
    mortgage_insurance_per: "0.75",
    start_month: "4",
    start_year: "2021",
  });

  const [newName, setNewName] = React.useState("Untitled Mortgage");

  const [edit1, setEdit1] = React.useState(false);
  const [edit2, setEdit2] = React.useState(false);

  const [loadingData, setLoadingData] = React.useState(false);
  const getData = function () {
    var ShareKey = window.location.href.split("/")[3].split("?")[0];

    //ShareKey = "tPtKsQ";

    if (ShareKey.length > 0) {
      setLoadingData(true);
      axios
        .post("/api/get.php", {
          ShareKey: ShareKey,
        })
        .then(function (response) {
          setLoadingData(false);
          setData(response.data);
          setNewName(response.data.title);
        })
        .catch(function (error) {
          setLoadingData(false);
          console.log(error);
        });
    } else {
    }
  };

  // Format number ex. 10,000,000.00
  const commaSeparateNum = (text) => {
    if (isNaN(parseFloat(text))) {
      return "0";
    }
    text = text.toString();
    text = text.replace(/,/g, "");
    text = text.replace(/[^0-9,.,-]/, "");
    text = text.replace(/ /g, "");
    text = text + "";
    var x = text.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      var replaceWith = "$1,$2";
      x1 = x1.replace(rgx, replaceWith);
    }
    return x1 + x2;
  };

  // Dollar Format
  const dollarFormat = (text) => {
    var Num = parseFloat(text.replace(/,/g, ""));
    Num = (Math.round(Num * 100) / 100).toFixed(0);
    if (Num < 0) {
      Num = Num * -1;
      return "-$" + commaSeparateNum(Num.toString());
    } else {
      return "$" + commaSeparateNum(Num.toString());
    }
  };

  const dollarFormatFull = (text) => {
    var Num = parseFloat(text.replace(/,/g, ""));
    Num = (Math.round(Num * 100) / 100).toFixed(2);
    if (Num < 0) {
      Num = Num * -1;
      return "-$" + commaSeparateNum(Num.toString());
    } else {
      return "$" + commaSeparateNum(Num.toString());
    }
  };

  const checkNaN = function (Input) {
    if (isNaN(parseFloat(Input))) {
      return "";
    } else {
      return parseFloat(Input.toString().replace(/,/g, ""));
      //return Input.toString();
    }
  };

  React.useEffect(() => {
    let home_price = parseFloat(data.home_price.toString().replace(/,/g, ""));

    // Down Payment
    let down_payment_per = parseFloat(
      data.down_payment_per.toString().replace(/,/g, "")
    );
    let down_payment_amt = (home_price * down_payment_per) / 100;
    let down_payment_amt_new = "";
    if (!isNaN(down_payment_amt)) {
      down_payment_amt_new = down_payment_amt.toFixed(0);
    }

    // Property Tax
    let property_tax_per = parseFloat(
      data.property_tax_per.toString().replace(/,/g, "")
    );
    let property_tax_amt = (home_price * property_tax_per) / 100;
    let property_tax_amt_new = "";
    if (!isNaN(property_tax_amt)) {
      property_tax_amt_new = property_tax_amt.toFixed(0);
    }

    // Home Owner Insurance
    let home_owner_insurance_per = parseFloat(
      data.home_owner_insurance_per.toString().replace(/,/g, "")
    );
    let home_owner_insurance_amt =
      (home_price * home_owner_insurance_per) / 100 / 12;
    let home_owner_insurance_amt_new = "";
    if (!isNaN(home_owner_insurance_amt)) {
      home_owner_insurance_amt_new = home_owner_insurance_amt.toFixed(0);
    }

    // Mortage Insurance
    let mortgage_insurance_per = parseFloat(
      data.mortgage_insurance_per.toString().replace(/,/g, "")
    );
    let mortgage_insurance_amt =
      ((home_price - down_payment_amt) * mortgage_insurance_per) / 100 / 12;
    let mortgage_insurance_amt_new = "0";
    if (
      !isNaN(mortgage_insurance_amt) &&
      down_payment_per < 20 &&
      mortgage_insurance_per > 0
    ) {
      mortgage_insurance_amt_new = mortgage_insurance_amt.toFixed(0);
    }

    setData({
      ...data,
      down_payment_amt: down_payment_amt_new,
      property_tax_amt: property_tax_amt_new,
      home_owner_insurance_amt: home_owner_insurance_amt_new,
      mortgage_insurance_amt: mortgage_insurance_amt_new,
    });
  }, [data.home_price]);

  // get Mortgage Insurance
  const getMortgageInsurance = (down_payment_amt, down_payment_per) => {
    let home_price = parseFloat(data.home_price.toString().replace(/,/g, ""));
    //let down_payment_per = parseFloat(data.down_payment_per.replace(/,/g, ""));
    //let down_payment_amt = (home_price * down_payment_per) / 100;

    let mortgage_insurance_per = parseFloat(
      data.mortgage_insurance_per.toString().replace(/,/g, "")
    );
    let mortgage_insurance_amt =
      ((home_price - down_payment_amt) * mortgage_insurance_per) / 100 / 12;
    let mortgage_insurance_amt_new = "";
    if (
      !isNaN(mortgage_insurance_amt) &&
      down_payment_per < 20 &&
      mortgage_insurance_per > 0
    ) {
      mortgage_insurance_amt_new = commaSeparateNum(
        mortgage_insurance_amt.toFixed(0).toString()
      );
    }
    return mortgage_insurance_amt_new;
  };

  // Round at Most 2 decimals
  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  };

  // Round at Most 3 decimals
  const roundToThree = (num) => {
    return +(Math.round(num + "e+3") + "e-3");
  };

  // result
  const result = () => {
    let monthly_payment = 0;
    let principal_interest = 0;
    let property_tax = 0;
    let hoa = 0;
    let home_owner_insurance = 0;
    let mortgage_insurance = 0;

    let home_price = parseFloat(data.home_price.toString().replace(/,/g, ""));
    if (isNaN(home_price)) {
      home_price = 0;
    }
    let down_payment = parseFloat(
      data.down_payment_amt.toString().replace(/,/g, "")
    );
    if (isNaN(down_payment)) {
      down_payment = 0;
    }
    let loan_amount = home_price - down_payment;
    let interest_rate = parseFloat(
      data.interest_rate.toString().replace(/,/g, "")
    );
    let loan_term = parseFloat(data.loan_term);
    if (isNaN(loan_term)) {
      loan_term = 1;
    }

    let P = loan_amount;
    let i = interest_rate / 100 / 12;
    let N = loan_term * 12;

    principal_interest =
      (P * (i * Math.pow(1 + i, N))) / (Math.pow(1 + i, N) - 1);
    if (isNaN(principal_interest)) {
      principal_interest = 0;
    }

    if (data.property_tax_amt !== "") {
      property_tax =
        parseFloat(data.property_tax_amt.toString().replace(/,/g, "")) / 12;
    }

    if (data.hoa !== "") {
      hoa = parseFloat(data.hoa.toString().replace(/,/g, ""));
    }

    if (data.home_owner_insurance_amt !== "") {
      home_owner_insurance = parseFloat(
        data.home_owner_insurance_amt.toString().replace(/,/g, "")
      );
    }

    if (data.mortgage_insurance_amt !== "") {
      mortgage_insurance = parseFloat(
        data.mortgage_insurance_amt.toString().replace(/,/g, "")
      );
    }

    monthly_payment =
      principal_interest +
      property_tax +
      hoa +
      home_owner_insurance +
      mortgage_insurance;

    let res = {
      monthly_payment: monthly_payment,
      principal_interest: principal_interest,
      property_tax: property_tax,
      hoa: hoa,
      home_owner_insurance: home_owner_insurance,
      mortgage_insurance: mortgage_insurance,
    };
    return res;
  };

  const editBox = React.useRef(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [modalIsOpenShare, setIsOpenShare] = React.useState(false);
  function openModalShare() {
    setIsOpenShare(true);
  }
  function closeModalShare() {
    setIsOpenShare(false);
  }
  const [shareLink, setShareLink] = React.useState("");
  function saveData() {
    axios
      .post("/api/save.php", {
        newName: newName,
        data: data,
      })
      .then(function (response) {
        window.history.pushState(
          response.data.ShareKey,
          newName,
          "/" + response.data.mortgage_id
        );
        setShareLink("https://m.breakproject.com/" + response.data.mortgage_id);

        document.title = newName;
        setData({ ...data, title: newName });
        closeModal();
        openModalShare();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <MetaTags>
        <meta property="og:title" content={data.PageName} />
      </MetaTags>
      <AppBar
        position="static"
        style={{
          background: darkState ? "#383838" : "white",
          color: darkState ? "white" : "black",
        }}
      >
        <Toolbar>
          <Tooltip title="Edit Calculation" aria-label="Edit" placement="right">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              background="primary"
              aria-label="menu"
              onClick={() => {
                setEdit1(true);
                setEdit2(true);
                editBox.current.scrollIntoView();
              }}
            >
              <img
                src={darkState ? NavIconDark : NavIconLight}
                alt="Logo Transparent"
                style={{ width: 22, borderRadius: 3 }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            component="h1"
            variant="h6"
            className={classes.title}
            style={{
              textAlign: "center",
              noWrap: true,
              overflow: "hidden",
              textOverflow: "hidden",
              maxHeight: "1.6em",
              paddingRight: 10,
            }}
          >
            {!loadingData && data.title}
          </Typography>
          <Tooltip
            title={darkState ? "Light Mode" : "Dark Mode"}
            aria-label="Dark"
            placement="right"
          >
            <IconButton
              edge="end"
              onClick={() => {
                var newDarkState = !darkState;
                if (!newDarkState) {
                  var expires = new Date();
                  expires.setDate(expires.getDate() + 365);
                  cookie.save("lightMode", true, {
                    path: "/",
                    expires: expires,
                  });
                } else {
                  cookie.remove("lightMode", { path: "/" });
                }
                setDarkState(newDarkState);
              }}
            >
              {!darkState && <Brightness2OutlinedIcon />}
              {darkState && <WbSunnyOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      {!loadingData ? (
        <Container component="main" maxWidth="md">
          <CssBaseline />

          {/*
          <div style={{ paddingTop: 20 }}></div>
          <div>{JSON.stringify(result().monthly_payment.toString())}</div>
          <div>{JSON.stringify(result().principal_interest.toString())}</div>
          <div>{JSON.stringify(result().property_tax.toString())}</div>
          <div>{JSON.stringify(result().hoa.toString())}</div>
          <div>{JSON.stringify(result().home_owner_insurance.toString())}</div>
          <div>{JSON.stringify(result().mortgage_insurance.toString())}</div>
          <div>{JSON.stringify(result())}</div>
          */}

          <div
            style={{
              textAlign: "center",
              fontSize: 28,
              padding: 15,
            }}
          >
            {dollarFormat(
              commaSeparateNum(result().monthly_payment.toString())
            )}
            {" a month"}
          </div>

          <ColorBar
            barContainerStyle={{ marginBottom: 10 }}
            legendRowContainerStyle={{
              display: "inline-block",
              width: isTabletOrMobile ? "100%" : "50%",
            }}
            tooltipStyle={{
              backgroundColor: "#ccc",
            }}
            data={[
              {
                value: parseFloat(result().principal_interest),
                color: "#7ed957",
                legendLabel: (
                  <Typography className={classes.legendLabel}>
                    Principal &amp; Interest
                  </Typography>
                ),
                legendValue: (
                  <Typography
                    className={[classes.legendLabel, classes.legendOdd]}
                  >
                    {dollarFormat(
                      commaSeparateNum(result().principal_interest.toString())
                    )}
                  </Typography>
                ),
              },
              {
                value: parseFloat(result().property_tax),
                color: "#D9B26F",
                legendLabel: (
                  <Typography className={classes.legendLabel}>
                    Property Tax
                  </Typography>
                ),
                legendValue: (
                  <Typography className={classes.legendLabel}>
                    {dollarFormat(
                      commaSeparateNum(result().property_tax.toString())
                    )}
                  </Typography>
                ),
              },
              {
                value: parseFloat(result().hoa),
                color: "#A37B73",
                legendLabel: (
                  <Typography className={classes.legendLabel}>
                    HOA Dues
                  </Typography>
                ),
                legendValue: (
                  <Typography
                    className={[classes.legendLabel, classes.legendOdd]}
                  >
                    {dollarFormat(commaSeparateNum(result().hoa.toString()))}
                  </Typography>
                ),
              },
              {
                value: parseFloat(result().home_owner_insurance),
                color: "#A6B1E1",
                legendLabel: (
                  <Typography className={classes.legendLabel}>
                    Home Insurance
                  </Typography>
                ),
                legendValue: (
                  <Typography className={classes.legendLabel}>
                    {dollarFormat(
                      commaSeparateNum(result().home_owner_insurance.toString())
                    )}
                  </Typography>
                ),
              },
              {
                value: parseFloat(result().mortgage_insurance),
                color: "#D34F73",
                legendLabel: (
                  <Typography className={classes.legendLabel}>PMI</Typography>
                ),
                legendValue: (
                  <Typography
                    className={[classes.legendLabel, classes.legendOdd]}
                  >
                    {dollarFormat(
                      commaSeparateNum(result().mortgage_insurance.toString())
                    )}
                  </Typography>
                ),
              },
            ]}
          />

          <Grid container spacing={2} ref={editBox}>
            <Grid item md={6} xs={12}>
              {edit1 ? (
                <Paper style={{ padding: "5px 15px 15px 15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Home Price"}
                        placeholder={"1"}
                        type="number"
                        value={checkNaN(data.home_price)}
                        onChange={(event) => {
                          if (
                            event.target.value > 0 ||
                            event.target.value === ""
                          ) {
                            var nextValue = event.target.value;
                            if (
                              parseFloat(
                                nextValue.toString().replace(/,/g, "")
                              ) > 999999999
                            ) {
                              nextValue = "999999999";
                            }
                            setData({
                              ...data,
                              home_price: nextValue,
                            });
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">{""}</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Down Payment"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.down_payment_amt)}
                        onChange={(event) => {
                          var amt = event.target.value;

                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );

                          let new_amt = parseFloat(amt.replace(/,/g, ""));
                          if (new_amt > home_price) {
                            new_amt = home_price;
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          let new_per = roundToThree(
                            (new_amt / home_price) * 100
                          );
                          if (isNaN(new_per)) {
                            new_per = "";
                          }

                          let new_mortgage_insurance_amt = getMortgageInsurance(
                            new_amt,
                            new_per
                          );

                          setData({
                            ...data,
                            down_payment_amt: new_amt,
                            down_payment_per: new_per,
                            mortgage_insurance_amt: new_mortgage_insurance_amt,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">{""}</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Down Rate"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.down_payment_per)}
                        onChange={(event) => {
                          var per = event.target.value;

                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );

                          let new_per = parseFloat(per.replace(/,/g, ""));
                          if (new_per > 100) {
                            new_per = 100;
                          }

                          let new_amt = "";
                          if (new_per !== "") {
                            new_amt = Math.round((home_price * new_per) / 100);
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          if (isNaN(new_per) || per === "") {
                            new_per = "";
                          }

                          let new_mortgage_insurance_amt = getMortgageInsurance(
                            new_amt,
                            new_per
                          );

                          if (per.indexOf(".") !== -1) {
                            new_per = new_per.toString() + ".";
                          }

                          setData({
                            ...data,
                            down_payment_amt: new_amt,
                            down_payment_per: new_per,
                            mortgage_insurance_amt: new_mortgage_insurance_amt,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {"%"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Loan Term"}
                        placeholder={"1"}
                        type="number"
                        value={checkNaN(data.loan_term)}
                        onChange={(event) => {
                          var newText = event.target.value;

                          let new_term = "";
                          if (!isNaN(parseFloat(newText))) {
                            new_term = newText;
                          }
                          if (parseFloat(new_term) > 100) {
                            new_term = "100";
                          }

                          setData({
                            ...data,
                            loan_term: new_term,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {"years"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Interest Rate"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.interest_rate)}
                        onChange={(event) => {
                          var newText = event.target.value;
                          let new_per = "";
                          if (!isNaN(parseFloat(newText)) || newText === ".") {
                            new_per = newText;
                          }
                          if (parseFloat(new_per) > 100) {
                            new_per = "100";
                          }

                          setData({
                            ...data,
                            interest_rate: new_per,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div style={{ textAlign: "right", marginTop: 7 }}>
                    <Button
                      onClick={() => {
                        var home_price = data.home_price;
                        if (home_price === "") {
                          home_price = 1;
                        }
                        var down_payment_amt = data.down_payment_amt;
                        if (down_payment_amt === "") {
                          down_payment_amt = 0;
                        }
                        var down_payment_per = data.down_payment_per;
                        if (down_payment_per === "") {
                          down_payment_per = 0;
                        }

                        var loan_term = data.loan_term;
                        if (loan_term === "") {
                          loan_term = 1;
                        }
                        var interest_rate = data.interest_rate;
                        if (interest_rate === "") {
                          interest_rate = 0;
                        }

                        setData({
                          ...data,
                          home_price: home_price,
                          down_payment_amt: down_payment_amt,
                          loan_term: loan_term,
                          interest_rate: interest_rate,
                        });

                        setEdit1(false);
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Update
                    </Button>
                  </div>
                </Paper>
              ) : (
                <Tooltip
                  title="Click to edit"
                  aria-label="Edit"
                  placement="bottom"
                >
                  <TableContainer
                    component={Paper}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEdit1(true);
                    }}
                  >
                    <Table aria-label="spanning table">
                      <TableRow>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            Home Price
                          </Typography>
                          <Typography className={classes.inputValue}>
                            ${commaSeparateNum(data.home_price)}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            Down Payment
                          </Typography>
                          <Typography className={classes.inputValue}>
                            ${commaSeparateNum(data.down_payment_amt)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            Loan Term
                          </Typography>
                          <Typography className={classes.inputValue}>
                            {data.loan_term} years
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            Interest Rate
                          </Typography>
                          <Typography className={classes.inputValue}>
                            {data.interest_rate}%
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Tooltip>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              {edit2 ? (
                <Paper style={{ padding: "5px 15px 15px 15px" }}>
                  {/* --- HOA --- */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"HOA Dues"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.hoa)}
                        onChange={(event) => {
                          if (
                            event.target.value > 0 ||
                            event.target.value === ""
                          ) {
                            setData({
                              ...data,
                              hoa: event.target.value,
                            });
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {" / month"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* --- Property Tax --- */}
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Property Tax"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.property_tax_amt)}
                        onChange={(event) => {
                          var amt = event.target.value;

                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );

                          let new_amt = parseFloat(amt.replace(/,/g, ""));
                          if (new_amt > home_price) {
                            new_amt = home_price;
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          let new_per = roundToThree(
                            (new_amt / home_price) * 100
                          );
                          if (isNaN(new_per)) {
                            new_per = "";
                          }

                          setData({
                            ...data,
                            property_tax_amt: new_amt,
                            property_tax_per: new_per,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {" / year"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Tax Rate"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.property_tax_per)}
                        onChange={(event) => {
                          var per = event.target.value;

                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );

                          let new_per = parseFloat(per.replace(/,/g, ""));
                          if (new_per > 100) {
                            new_per = 100;
                          }

                          let new_amt = "";
                          if (new_per !== "") {
                            new_amt = Math.round((home_price * new_per) / 100);
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          if (isNaN(new_per) || per === "") {
                            new_per = "";
                          }

                          if (per.indexOf(".") !== -1) {
                            new_per = new_per.toString() + ".";
                          }

                          setData({
                            ...data,
                            property_tax_amt: new_amt,
                            property_tax_per: new_per,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {"%"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* --- Home Insurance --- */}
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Home Insurance"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.home_owner_insurance_amt)}
                        onChange={(event) => {
                          var amt = event.target.value;

                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );

                          let new_amt = parseFloat(amt.replace(/,/g, ""));
                          if (new_amt > home_price) {
                            new_amt = home_price;
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          let new_per = roundToThree(
                            ((new_amt * 12) / home_price) * 100
                          );
                          if (isNaN(new_per)) {
                            new_per = "";
                          }

                          setData({
                            ...data,
                            home_owner_insurance_amt: new_amt,
                            home_owner_insurance_per: new_per,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {" / month"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"Insurance Rate"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.home_owner_insurance_per)}
                        onChange={(event) => {
                          var per = event.target.value;
                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );

                          let new_per = parseFloat(per.replace(/,/g, ""));
                          if (new_per > 100) {
                            new_per = 100;
                          }

                          let new_amt = "";
                          if (new_per !== "") {
                            new_amt = Math.round(
                              (home_price * new_per) / 100 / 12
                            );
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          if (isNaN(new_per) || per === "") {
                            new_per = "";
                          }

                          if (per.indexOf(".") !== -1) {
                            new_per = new_per.toString() + ".";
                          }

                          setData({
                            ...data,
                            home_owner_insurance_amt: new_amt,
                            home_owner_insurance_per: new_per,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {"%"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* --- PMI --- */}
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      {parseFloat(data.down_payment_per) < 20 ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label={"PMI"}
                          placeholder={"0"}
                          type="number"
                          value={checkNaN(data.mortgage_insurance_amt)}
                          onChange={(event) => {
                            var amt = event.target.value;
                            let home_price = parseFloat(
                              data.home_price.toString().replace(/,/g, "")
                            );
                            let down_payment = parseFloat(
                              data.down_payment_amt.toString().replace(/,/g, "")
                            );

                            let new_amt = parseFloat(amt.replace(/,/g, ""));
                            if (new_amt > home_price / 10) {
                              new_amt = home_price / 10;
                            }

                            if (isNaN(new_amt)) {
                              new_amt = "";
                            }

                            let new_per = roundToThree(
                              ((new_amt * 12) / (home_price - down_payment)) *
                                100
                            );
                            if (isNaN(new_per)) {
                              new_per = "";
                            }

                            setData({
                              ...data,
                              mortgage_insurance_amt: new_amt,
                              mortgage_insurance_per: new_per,
                            });
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {" / month"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label={"PMI"}
                          disabled={true}
                          value={"Not Required"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={"PMI Rate"}
                        placeholder={"0"}
                        type="number"
                        value={checkNaN(data.mortgage_insurance_per)}
                        onChange={(event) => {
                          var per = event.target.value;
                          let home_price = parseFloat(
                            data.home_price.toString().replace(/,/g, "")
                          );
                          let down_payment = parseFloat(
                            data.down_payment_amt.toString().replace(/,/g, "")
                          );

                          let new_per = parseFloat(per.replace(/,/g, ""));
                          if (new_per > 10) {
                            new_per = 10;
                          }

                          let new_amt = "";
                          if (
                            new_per !== "" &&
                            parseFloat(
                              data.down_payment_per.toString().replace(/,/g, "")
                            ) < 20
                          ) {
                            new_amt = Math.round(
                              ((home_price - down_payment) * new_per) / 100 / 12
                            );
                          }

                          if (isNaN(new_amt)) {
                            new_amt = "";
                          }

                          if (isNaN(new_per) || per === "") {
                            new_per = "";
                          }

                          if (per.indexOf(".") !== -1) {
                            new_per = new_per.toString() + ".";
                          }

                          setData({
                            ...data,
                            mortgage_insurance_amt: new_amt,
                            mortgage_insurance_per: new_per,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {"%"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div style={{ textAlign: "right", marginTop: 7 }}>
                    <Button
                      onClick={() => {
                        var property_tax_amt = data.property_tax_amt;
                        if (property_tax_amt === "") {
                          property_tax_amt = 0;
                        }
                        var hoa = data.hoa;
                        if (hoa === "") {
                          hoa = 0;
                        }
                        var home_owner_insurance_amt =
                          data.home_owner_insurance_amt;
                        if (home_owner_insurance_amt === "") {
                          home_owner_insurance_amt = 0;
                        }
                        var mortgage_insurance_amt =
                          data.mortgage_insurance_amt;
                        if (mortgage_insurance_amt === "") {
                          mortgage_insurance_amt = 0;
                        }

                        setData({
                          ...data,
                          property_tax_amt: property_tax_amt,
                          hoa: hoa,
                          home_owner_insurance_amt: home_owner_insurance_amt,
                          mortgage_insurance_amt: mortgage_insurance_amt,
                        });

                        setEdit2(false);
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Update
                    </Button>
                  </div>
                </Paper>
              ) : (
                <Tooltip
                  title="Click to edit"
                  aria-label="Edit"
                  placement="bottom"
                >
                  <TableContainer
                    component={Paper}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEdit2(true);
                    }}
                  >
                    <Table aria-label="spanning table">
                      <TableRow>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            HOA Dues
                          </Typography>
                          <Typography className={classes.inputValue}>
                            ${commaSeparateNum(data.hoa)} / mo
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            Property Tax
                          </Typography>
                          <Typography className={classes.inputValue}>
                            ${commaSeparateNum(data.property_tax_amt)} / yr
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            Home Insurance
                          </Typography>
                          <Typography className={classes.inputValue}>
                            ${data.home_owner_insurance_amt} / mo
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "50%" }}>
                          <Typography className={classes.inputTitle}>
                            PMI
                          </Typography>
                          <Typography className={classes.inputValue}>
                            $
                            {data.mortgage_insurance_amt === ""
                              ? "0"
                              : data.mortgage_insurance_amt}{" "}
                            / mo
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <div style={{ width: "100%", height: 200 }}></div>
        </Container>
      ) : null}

      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Tooltip title="Save Result" aria-label="Save" placement="left">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setEdit1(false);
              setEdit2(false);
              openModal();
            }}
          >
            <SaveIcon />
          </Fab>
        </Tooltip>
      </div>

      <Modal
        isOpen={modalIsOpen}
        contentLabel="Save Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            border: 0,
            backgroundColor: darkTheme.palette.background.default,
            padding: 0,
          },
        }}
      >
        <div style={{ fontSize: 22, padding: "10px 15px" }}>Save Mortgage</div>

        <Divider />

        <div style={{ padding: 15 }}>
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            fullWidth
            label={"Mortgage Name"}
            placeholder={"1500 Central Ave"}
            value={newName}
            onChange={(event) => {
              setNewName(event.target.value);
            }}
          />

          <div style={{ textAlign: "right", marginTop: 10 }}>
            <Button onClick={closeModal} style={{ float: "left" }}>
              Cancel
            </Button>

            <Button
              disabled={newName == "" ? true : false}
              variant="contained"
              color="secondary"
              onClick={saveData}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenShare}
        contentLabel="Share Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            border: 0,
            backgroundColor: darkTheme.palette.background.default,
            padding: 0,
          },
        }}
      >
        <div style={{ fontSize: 22, padding: "10px 15px" }}>Share Link</div>

        <Divider />

        <div style={{ padding: 15 }}>
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            fullWidth
            label={"Public URL"}
            value={shareLink}
          />

          <div style={{ textAlign: "right", marginTop: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={closeModalShare}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default App;
